<template>
  <div>
    <Echart
        :options="options"
        id="RightBottomChart"
        height="400px"
        width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart/index.vue'

export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            top: '2%',
            data: newData.category,
            textStyle: {// 设置字体颜色
              fontSize: 14, // 设置字体大小
              // fontWeight: 'bold'
            }
          },
          grid: {
            top: '10%',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'time',
            splitLine: {show: false},
            axisLabel: {
              textStyle: {// 设置字体颜色
                fontSize: 16, // 设置字体大小
                fontWeight: 'bold'
              }

            },
          },
          yAxis: {
            type: 'value',
            splitLine: {show: true},
            axisLabel: {
              textStyle: {// 设置字体颜色
                fontSize: 16 // 设置字体大小
              },
              formatter: '{value} min'
            },

          },
          series: [
            {
              name: newData.category[0],
              type: 'line',
              // stack: 'Total',
              symbol: 'none',
              data: newData.data[0],
              tooltip: {
                valueFormatter: function (value) {
                  return value + ' min';
                }
              },
            },
            {
              name: newData.category[1],
              type: 'line',
              // stack: 'Total',
              symbol: 'none',
              data: newData.data[1],
              tooltip: {
                valueFormatter: function (value) {
                  return value + ' min';
                }
              },
            },
            {
              name: newData.category[2],
              type: 'line',
              symbol: 'none',
              // stack: 'Total',
              data: newData.data[2],
              tooltip: {
                valueFormatter: function (value) {
                  return value + ' min';
                }
              },
            },
            {
              name: newData.category[3],
              type: 'line',
              // stack: 'Total',
              symbol: 'none',
              data: newData.data[3],
              tooltip: {
                valueFormatter: function (value) {
                  return value + ' min';
                }
              },
            },
            {
              name: newData.category[4],
              type: 'line',
              // stack: 'Total',
              symbol: 'none',
              data: newData.data[4],
              tooltip: {
                valueFormatter: function (value) {
                  return value + ' min';
                }
              },
            }
          ]
        }
      },
      immediate: true,
      deep: true
    },
  },
}
</script>
