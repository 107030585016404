<template>
  <div>
    <Chart :cdata="cdata"/>
  </div>
</template>

<script>
import Chart from './chart.vue'

export default {
  data() {
    return {
      cdata: {
        category: [],
        data: [],
      }
    };
  },
  props: {
    oee_trend: {
      type: Array,
      required: true,
    },
  },
  watch: {
    oee_trend: {
      handler: function (newVal) {
        // console.log("-----------------")
        // console.log(newVal)
        this.cdata.category = []
        this.cdata.data = [[], [], [], [], []]
        newVal.forEach((item) => {
          let index = item.machine_status_id - 5;
          if (!this.cdata.category.includes(item.status_name))
            this.cdata.category.push(item.status_name)
          // console.log(this.cdata.data[index])
          this.cdata.data[index].push([item.date, item.status_count])
        })
        // console.log("-----------------")
        // console.log(this.cdata.category)
        // console.log(this.cdata.data)
      },
      deep: true
    }
  },
  components: {
    Chart,
  },
  mounted() {
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
